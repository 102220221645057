module.exports = [
  'Whatsapp',
  'Facebook',
  'Instagram',
  'Tinder',
  'Bumble',
  'ChatGPT',
  'Telegram',
  'Uber',
  'Gmail',
  'Reddit',
  'Google',
  'Twitter',
  'Discord',
  'IMO',
  'Tiktok',
  'Viber',
  'Youtube',
  'Ebay',
  'Paypal',
  'Netflix',
  'Linkedin',
  'Etsy',
  'Pinterest',
  'Spotify',
  'Craigslist',
  'BBC'
  // 'WeChat'
]
