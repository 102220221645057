import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import axios from 'axios'
import Linkify from 'react-linkify'
import styled from 'styled-components'
import { Box, Flex, DesktopContainer, MobileContainer, ResponsiveColRow } from '../components/ui/Div'
import Button, { PillButton } from '../components/ui/Button'
import Spinner from '../components/ui/Spinner'
import { colors, smallBoxShadow, shuffle } from '../components/ui/helpers.js'
import { urlToString, stringToUrl } from '../components/ui/formatters.js'
import countryToIso2 from '../../constants/countryToIso2'
import { H1, H2, H3, H4, SeoH2, SeoH4, Span, Text, LinkSpan } from '../components/ui/Text.js'
import OnlineIcon from '../components/ui/OnlineIcon'
import Image from '../components/ui/Image'
import Layout from '../components/layout'
import Helmet from '../components/Helmet'
import Footer from '../components/Footer'
import telephones from '../../constants/telephones'
import searches from '../../constants/searches'
import useCases from '../../constants/useCases'

const BreadCrumbContainer = styled(Flex)`
  position: absolute;
  top: 0px;
  left: 0px;

  padding-left: 100px;
  p {
    font-weight: 300;
  }
  @media (max-width: 1200px) {
    padding-left: 22px;
  }
  @media (max-width: 500px) {
    padding-top: 16px;
    p {
      font-size: 14px;
      color: white;
    }
    span {
      color: white;
      margin-left: 10px;
      margin-right: -6px;
    }
  }
`

const HomeImage = styled(Image)`
  @media (max-width: 500px) {
    display: none;
  }
`

const Li = styled.li`
  display: inline-block;
`

const Ul = styled.ul`
  display: flex;
  align-items: center;
`

const Breadcrumbs = ({ country, useCase, search }) => {
  return (
    <BreadCrumbContainer pt="24px" alignItems="end" zIndex={1} aria-label="breadcrumbs">
      <Ul>
        <Li>
          <a href={`/`}>
            <Flex width="auto" alignItems="flex-end">
              <HomeImage alt="home icon" mb="4px" mr="14px" width="16px" height="16px" src="/homePurple.svg" />
              <Text color="#E9D7FD" fontSize="14px" bold>
                Home
                <Span color="#E9D7FD" fontSize="14px" ml="16px">
                  >
                </Span>
              </Text>
            </Flex>
          </a>
        </Li>
        <Li>
          <a href={`/${stringToUrl(country || useCase || search)}/`}>
            <Flex width="auto" alignItems="flex-end">
              <Text color="#E9D7FD" fontSize="14px" bold ml="16px">
                {urlToString(country || useCase || search)}
              </Text>
            </Flex>
          </a>
        </Li>
      </Ul>
    </BreadCrumbContainer>
  )
}

export default Breadcrumbs
